<template>
  <v-card v-if="isVisible" v-bind="$attrs">
    <v-system-bar>Privat</v-system-bar>
    <v-alert
      v-if="info.dataBlocked"
      type="info"
      text
      tile
      :prominent="!$vuetify.breakpoint.xs"
    >
      <span v-if="$_isPerson(person)">
        Datensperrung ist aktiviert. Diese persönlichen Angaben sind nur für die
        Schuladministration sichtbar.
      </span>
      <span v-else
        >Datensperrung ist aktiviert. Persönliche Angaben dürfen nicht
        herausgegeben werden.</span
      >
    </v-alert>
    <v-list subheader>
      <ListItem title="soziales Geschlecht (Gender)">
        <LookupValue :value="info.gender" />
      </ListItem>
      <ListItem v-if="!info.dataBlocked" title="Geburtsdatum">
        <DateValue
          :value="info.birthday"
          :showVisibility="showVisibility"
          :visibility="info.birthdayVisibility"
        />
      </ListItem>
      <ListItem title="Alter">
        <StaticText :value="info.age" />
      </ListItem>
      <ListItem
        v-if="info.town"
        title="Adresse"
        :showVisibility="showVisibility"
        :visibility="info.addressVisibility"
      >
        <PostalAddress :person="info" />
      </ListItem>
      <ListItem
        v-if="info.phoneHome"
        title="Telefon privat"
        :showVisibility="showVisibility"
        :visibility="info.phoneHomeVisibility"
      >
        <PhoneNumber :value="info.phoneHome" />
      </ListItem>
      <ListItem
        v-if="info.phoneMobile"
        :showVisibility="showVisibility"
        :visibility="info.phoneMobileVisibility"
        title="Mobiltelefon"
      >
        <PhoneNumber :value="info.phoneMobile" />
      </ListItem>
    </v-list>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import ListItem from "@/components/ListItem.vue";
import LookupValue from "common/components/LookupValue.vue";
import PhoneNumber from "common/components/Person/PhoneNumber.vue";
import PostalAddress from "common/components/Person/PostalAddress.vue";
import StaticText from "common/components/StaticText.vue";

export default defineComponent({
  name: "ContactPersonal",
  components: {
    DateValue,
    ListItem,
    LookupValue,
    PhoneNumber,
    PostalAddress,
    StaticText,
  },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      info: null,
    };
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isVisible() {
      return this.hasPerson && this.info;
    },
    showVisibility() {
      return this.$_isPerson(this.person);
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.info = null;
      if (!this.hasPerson) return;
      this.info = await this.apiGet({
        resource: "person/personalinfo",
        id: this.person.id,
      });
    },
  },
});
</script>
